<template>
  <div>
    <top-bar :title="'问题详情'" :left="true"></top-bar>
    <van-tabs v-model="activeName">
      <van-tab title="问题详情" class="info" :name="'tab1'">
        <van-cell-group>
         <van-cell>
           <template #title>
             <div class="blue-box"></div>
             <span class="custom-title">问题信息</span>
           </template>
         </van-cell>
          <van-cell title="问题来源" :value="detailData.sourceTypeStr"/>
          <van-cell title="提问人姓名" :value="detailData.name"/>
          <van-cell title="电话" :value="detailData.phoneNumber"/>
          <van-cell title="所在小区" :value="detailData.subArea"/>
          <van-cell title="位置" :value="detailData.houseFullName"/>
          <van-cell title="问题类别" :value="detailData.typeStr"/>
          <van-field v-model="detailData.content" rows="3" readonly autosize type="textarea" placeholder="输入问题描述" class="left"/>
          <van-row class="uploadBox">
            <van-col :span="24">
              <!-- <van-uploader v-model="fileList" :deletable="false" :show-upload="false"/> -->
              <div class="upload">
                <upload-file
                  :file-list="fileList"
                  :del-btn-show="edit"
                  :uploadPath="uploadPath"
                  :upload-btn-show="edit"
                  :file-type="'imageOrVideo'"/>
              </div>
            </van-col>
          </van-row>
        </van-cell-group>

        <van-cell-group style="margin-top: 10px;" v-if="problemData.status == 0 || problemData.status == 30">
          <van-cell>
            <template #title>
              <div class="blue-box"></div>
              <span class="custom-title">处理详情</span>
            </template>
          </van-cell>
          <van-cell title="处理人" :value="detailData.solveUserName"/>
          <van-cell title="联系电话" :value="detailData.solveUserPhoneNumber"/>
          <van-cell title="处理时间" :value="detailData.solveTime"/>
          <van-field v-model="detailData.solveDes" rows="3" autosize readonly type="textarea" placeholder="输入处理结果" class="left"/>
          <van-row class="uploadBox">
            <van-col :span="24">
              <!-- <van-uploader v-model="solveFileList" :deletable="false" :show-upload="false"/> -->
              <div class="upload">
                <upload-file
                  :file-list="solveFileList"
                  :del-btn-show="edit"
                  :upload-btn-show="edit"
                  :uploadPath="uploadPath"
                  :file-type="'imageOrVideo'"/>
              </div>
            </van-col>
          </van-row>
        </van-cell-group>
      </van-tab>

      <van-tab title="关联详情" :name="'tab2'">
        <div class="list-info">
          <div class="cont">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
              <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{dataList.length}}</span>&nbsp;条数据</div>
              <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id, item.visitBody, item.fullLocation)">
                <div class="title">
                  <div class="title-left">
                    {{detailData.visitInfoEntityVOS && detailData.visitInfoEntityVOS.length > 0 ? (item.createUserName + ' | ' + item.createTime) : (item.createUserName + ' | ' + (item.inspectionTime.split(' ')[0]))}}
                  </div>
                  <div class="title-right" :style="{color:item.status==10?'#FE5E5E':'#3E7EFE'}">
                    {{detailData.visitInfoEntityVOS && detailData.visitInfoEntityVOS.length > 0 ? item.serviceTypeStr : item.inspectionTypeStr}}
                  </div>
                </div>
                <div class="content">
                  <van-image :src="item.url?item.url:item.filesPath.length!==0?item.filesPath[0].url : require('@/assets/img/flower.png')" class="content-img">
                    <template slot="error">
                      <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                    </template>
                    <template slot="loading">
                      <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                    </template>
                  </van-image>
                  <div class="content-text" v-if="detailData.visitInfoEntityVOS && detailData.visitInfoEntityVOS.length > 0">
                    <div class="top-text">{{item.visitBody == 2 ? item.placeStr : item.fullLocation == null ? '无房户' : item.fullLocation}}</div>
                    <div class="bottom-text">{{item.visitDes}}</div>
                  </div>
                  <div class="content-text" v-if="detailData.inspectionInfoEntityVOS && detailData.inspectionInfoEntityVOS.length > 0">
                    <div class="top-text">{{item.address}}</div>
                    <div class="bottom-text">{{item.inspectionDes}}</div>
                  </div>
                  <van-icon name="arrow" color="#666" class="content-icon"/>
                </div>
              </div>
            </van-list>
            <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
          </div>
        </div>
      </van-tab>
    </van-tabs>

    <van-row v-if="problemData.status != 0 && problemData.status != 30 && activeName == 'tab1'" class="btns">
      <van-row v-if="problemData.status == 20">
        <van-col :span="24">
          <van-button plain type="info" size="large" round @click="handling" style="background-color:transparent;color:#FFA93A;border-color:#FFA93A">处理</van-button>
        </van-col>
      </van-row>
      <van-row gutter="10" v-if="problemData.status == 10">
        <van-col :span="12">
          <van-button type="info" size="large" round @click="change">编辑</van-button>
        </van-col>
        <van-col :span="12">
          <van-button plain type="info" size="large" round @click="handling" style="background-color:transparent;color:#FFA93A;border-color:#FFA93A">处理</van-button>
        </van-col>
      </van-row>
    </van-row>

  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {getImageStream} from '@/utils/index'
import uploadFile from '@/components/upload/uploadFile'

import {mapState, mapMutations} from 'vuex'

export default {
  data() {
    return {
      edit: false,
      value: '',
      detailData: {},
      fileList: [],
      solveFileList: [],
      uploadPath: 'files/problem/temporary',
      activeName: 'tab1',
      loading: false,
      finished: false,
      page: 0,
      limit: 10,
      totalCount: 0,
      dataList: []
    }
  },
  computed: {...mapState(['problemData'])},
  components: {
    topBar,
    uploadFile
  },
  methods: {
    ...mapMutations(['setProblemHandling']),
    getDetail() {
      console.log(this.problemData);
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/problem/info'),
        method: 'post',
        params: this.$httpApp.adornParams({
          id: this.problemData.id
        })
      }).then(({data}) => {
        if (data.code == 0) {
          if (data.entityVO.problemFiles && data.entityVO.problemFiles.length != 0) {
            data.entityVO.problemFiles.forEach(item => {
              console.log(item);
              let u;
              if (item.type == 1 || item.type == 2) {
                u = getImageStream(item.filePath)

                let url = {
                  uid: this.fileList.length,
                  id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                  status: 'done',//uploading上传中，done上传完成，error上传失败
                  fileType: item.type,
                  url: u,
                  relativePath: item.filePath,
                  realUrl: item.filePath.replace(/\\/g, "/")
                }
                this.fileList.push(url);
              }
            })
          }
          if (data.entityVO.problemSolveFiles && data.entityVO.problemSolveFiles.length != 0) {
            data.entityVO.problemSolveFiles.forEach(item => {
              console.log(item);
              let u;
              if (item.type == 1 || item.type == 2) {
                u = getImageStream(item.filePath)

                let url = {
                  uid: this.solveFileList.length,
                  id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                  status: 'done',//uploading上传中，done上传完成，error上传失败
                  fileType: item.type,
                  url: u,
                  relativePath: item.filePath,
                  realUrl: item.filePath.replace(/\\/g, "/")
                }
                this.solveFileList.push(url);
              }
            })
          }
          this.detailData = data.entityVO
          this.dataList = data.entityVO.inspectionInfoEntityVOS || data.entityVO.visitInfoEntityVOS || []
          this.finished = true
          this.$toast.clear()
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    getDataList(){},
    getInfo (id, visitType, location) {
      let type = ''
      if (visitType == 2) {
        type = '3'
      } else {
        type = location ? '1' : '2'
      }
      if (this.detailData.visitInfoEntityVOS && this.detailData.visitInfoEntityVOS.length > 0) {
        this.$router.push({path: '/visit-add', query: {id: id, type: type, isProblem: 1}})
      } else {
        this.$router.push({path: '/patrol-add', query: {id: id, isProblem: 1}})
      }
    },
    deleteHandle() {
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$httpApp({
          url: this.$httpApp.adornUrl('/wxapp/problem/delete'),
          method: 'post',
          params: this.$httpApp.adornParams({
            id: parseInt(this.detailData.id)
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$toast.success({
              message: '删除成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    change() {
      this.$router.push('/problem-change')
    },
    handling() {
      this.$router.push('/problem-handling')
    }
  },
  created() {
    document.querySelector('html').style.backgroundColor = '#fff'
    this.getDetail()
  },
  beforeDestroy() {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>

<style lang="scss" scoped>
.upload {
  padding-left: 30px;
}
</style>
